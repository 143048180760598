@import "../../styles/theme/app/variables.scss";

.label {
  color: $main-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
