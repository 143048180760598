@import "../../styles/theme/app/variables.scss";

.checkbox {
  .label {
    color: $main-font-clr;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
