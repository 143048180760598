@import "../../../styles/theme/app/variables.scss";

.textArea {
  min-height: 192px;
}

.label {
  color: $main-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-form {
  height: 5rem !important;
  font-size: 1.5rem;
}

.inputContainer {
  position: relative;

  .emojiContainer {
    position: absolute;
    top: 5px;
    right: 5px;

    &.rtl {
      right: unset;
      left: 55px;
    }
  }
}

.maxRecommended {
  position: absolute;
  font-size: 1.4rem;
  top: 112%;
  right: 2px;

  &.rtl {
    right: unset;
    left: 2px;
  }
}

.maxRecommendedReached {
  color: #dc3545;
}

.hint {
  margin-top: 8px;
  font-size: 16px;
  color: #9a9a9a;
  font-weight: bold;

  > span {
    color: $main-font-clr;
  }
}

.error {
  font-size: 13px;
  color: $error-text-clr;
}

.textarea {
  height: $main-input-height;
  color: $main-input-clr;
  font-size: $main-input-font-size;
  border-radius: $main-input-border-radius !important;
  font-weight: $main-input-font-weight;
  border: 1px solid $main-input-border-clr;
  background-color: $main-input-background-clr;
  padding: $main-input-padding;
  width: 100%;
  outline: none;
  transition: all $main-input-transition-duration
    $main-input-transition-timing-function;

  &:focus:not(:disabled) {
    border-color: $main-input-border-clr-focus;
    background-color: $main-input-background-clr-focus;
    color: $main-input-clr-focus;
  }

  &:hover:not(:disabled) {
    border: 1px solid $main-input-border-clr-hvr !important;
  }

  &:disabled {
    background-color: $main-input-background-clr-disabled;
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr;

    // disable the hover effect
    &:hover {
      border: 1px solid $main-input-border-clr !important;
      box-shadow: none !important;
    }
  }

  &::placeholder {
    color: $main-input-placeholder-clr;
    font-size: $main-input-placeholder-font-size;
    font-weight: $main-input-placeholder-font-weight;
  }

  &.rtl {
    direction: rtl;
  }
}
