@import "../../../styles/theme/app/variables";
.alert {
  position: absolute;
  top: 0%;
  left: 30%;
  width: 30%;
  background-color: #013874;
  color: #ffffff !important;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}
.inputRow {
  margin: 30px 0;

  .elementLabel {
    padding: 5px 0;
    font-weight: bold;
  }
  :global {
    .StripeElement {
      border: 1px $agio-blue-grey-dark solid;
      height: 40px;
      padding: 11px 10px;
      line-height: 36px;
      border-radius: 3px;
      width: 100%;
    }
  }
}
.edit {
  .inputRow {
    margin: 0 0 20px 0;
  }
}
